<template>
  <div class="rule">
    <!--  标签页-->
    <el-tabs v-model="activeName" class="el-nav">
      <el-tab-pane label="课程管理" name="course">
        <!--        搜索查询-->
        <div class="search space-between" style="margin: 15px 0 30px 0">
          <div class="flex-nowrap">
            <el-select v-model="options" placeholder="请选择选项" class="search-types">
              <el-option
                  v-for="item in selectData"
                  :key="item.label"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>

            <el-input v-model="keywords" placeholder="请输入查询条件" class="search-input"></el-input>
             <span style="margin-right: 10px;">难度</span>
            <el-select v-model="level" placeholder="请选择" class="search-type">
              <el-option
                  v-for="item in levelData"
                  :key="item.label"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
            <span style="margin-right: 10px;margin-left: 20px">状态</span>
            <el-select v-model="status" placeholder="请选择状态" class="search-type" style="margin-right: 30px">
              <el-option
                  v-for="item in statusData"
                  :key="item.label"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
            <el-button type="primary" class="search-btn" @click="searchSubmit">查询</el-button>
            <el-button class="search-btn reset" @click="resetFun">重置</el-button>
          </div>
          <el-button type="warning" class="f-r search-add" @click="add()">新增课程</el-button>
        </div>

        <div class="total space-between">
          <div class="total-left">共有 <span class="total-left-text">{{courseInfo.meta ? courseInfo.meta.total > 9999 ? (courseInfo.meta.total / 10000).toFixed(2) + 'W' : courseInfo.meta.total : 0}}</span> 门课程</div>
<!--          <el-button type="text" class="total-right">批量删除</el-button>-->
        </div>
        <!--        表格-->
        <div v-if="courseInfo.data && courseInfo.data.length">
          <el-table
              class="table"
              ref="multipleTable"
              :data="courseInfo.data"
              tooltip-effect="dark"
              style="width: 100%"
              @selection-change="handleSelectionChange"
              :header-cell-style="{background:'#F6F8F9',color: '#1C1F21',fontWeight:'400'}"
              border>

            <el-table-column
                label="编号"
                width="90">
              <template slot-scope="scope">{{ scope.row.id }}</template>
            </el-table-column>
            <el-table-column
                label="名称"
                show-overflow-tooltip>
              <template slot-scope="scope">
                <img :src="scope.row.pc_cover_picture" class="table-img" alt="">
                <div class="table-item-name white-space" style="max-width: 340px">{{ scope.row.title }}</div>
                <div class="table-item-tag" v-if="scope.row.tags && scope.row.tags.length">标签： <span v-for="(item, tag_index) in scope.row.tags.slice(0,4)" :key="tag_index">{{ item.name.length > 6 ? item.name.substring(0,6) + '...' : item.name }} <span
                    v-if="tag_index < 3">、</span></span></div>
                <div class="table-item-state vertical-center">
                  <div>
                    <el-tag
                        v-if="scope.row.status === 0"
                        effect="dark">
                      草稿
                    </el-tag>
                    <el-tag
                        v-else-if="scope.row.status === 1"
                        class="table-item-tag-blue"
                        effect="dark">
                      发布
                    </el-tag>
                    <el-tag
                        v-else-if="scope.row.status === -1"
                        class="table-item-tag-block"
                        effect="dark">
                      关闭
                    </el-tag>
                  </div>
                  <div class="top-status" @click="setTop(scope.row.uuid)">
                    <el-tag :class="scope.row.is_top ? 'table-item-tag-gray' : 'table-item-tag-orange'" effect="dark">顶</el-tag>
                  </div>

                </div>
              </template>
            </el-table-column>
            <el-table-column
                label="学员人数"
                width="100">
              <template slot-scope="scope"><span class="">{{ scope.row.is_share ? '/' : (scope.row.learn_num + scope.row.buy_num) > 9999 ? ((scope.row.learn_num + scope.row.buy_num) / 10000).toFixed(2) + 'W' : (scope.row.learn_num + scope.row.buy_num)}}</span></template>
            </el-table-column>
            <el-table-column
                prop="lecturer"
                label="讲师"
                width="130">
            </el-table-column>
            <el-table-column
                label="操作"
                align="center"
                width="120">
              <template slot-scope="scope">
                <router-link :to="'/courses/class/'+scope.row.uuid">
                  <el-button type="text" size="small" class="table-edit">管理</el-button>
                </router-link>
                <span style="color: #D0D6DE;margin: 0 8px">|</span>
                <el-dropdown @command="handleCommand" trigger="click">
                <span class="table-edit">
                  更多
                </span>
                  <el-dropdown-menu slot="dropdown"  class="drop">
                    <el-dropdown-item :command="['edit',scope.row]" v-if="scope.row.status != -1 && !scope.row.is_share"><i class="el-icon-check" style="opacity: 0"></i>编辑</el-dropdown-item>
                    <el-dropdown-item :command="['view',scope.row]"><i class="el-icon-check" style="opacity: 0"></i>查看</el-dropdown-item>
                    <div class="" v-if="!scope.row.is_share">
                    <div class="set-line"></div>
                    <el-dropdown-item :command="['1',scope.row]"><i class="el-icon-check" :style="{'opacity': scope.row.content_status === 1 ? 1 :0}"></i>允许任何人评论</el-dropdown-item>
                    <el-dropdown-item :command="['2',scope.row]"><i class="el-icon-check" :style="{'opacity': scope.row.content_status === 2 ? 1 :0}"></i>评价筛选后显示</el-dropdown-item>
                    <el-dropdown-item :command="['3',scope.row]"><i class="el-icon-check" :style="{'opacity': scope.row.content_status === 3 ? 1 :0}"></i>关闭评论</el-dropdown-item>
                    <div class="set-line"></div>
                    <el-dropdown-item :command="['close',scope.row]" v-if="scope.row.status === 1"><i class="el-icon-check" style="opacity: 0"></i>关闭</el-dropdown-item>
                    <el-dropdown-item :command="['push',scope.row]" v-if="(scope.row.status === -1 || scope.row.status === 0) && scope.row.publish_lesson_num != 0"><i class="el-icon-check" style="opacity: 0"></i>发布</el-dropdown-item>
                    <el-dropdown-item :command="['del',scope.row]" v-if="scope.row.status === -1 || scope.row.status === 0"><i class="el-icon-check" style="opacity: 0"></i>删除</el-dropdown-item>
                    </div>

                  </el-dropdown-menu>
                </el-dropdown>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <empty-prompt style="margin-top: 120px;" v-else></empty-prompt>


        <!--   分页     -->
        <el-pagination
            v-if="courseInfo.meta && courseInfo.meta.last_page > 1"
            class="page"
            background
            @current-change="handleCurrentChange"
            :current-page="page"
            :page-sizes="[20, 50, 100]"
            :page-size="pageSize"
            layout="prev, pager, next,total, jumper"
            :total="courseInfo.meta && courseInfo.meta.total">
        </el-pagination>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import _ from 'lodash'
import EmptyPrompt from "components/EmptyPrompt";
export default {
  name: "Courses",
  data() {
    return {
      isAdd: true,
      activeName: 'course',
      selectData: [
        {
          value: 'title',
          label: '标题'
        }, {
          value: 'id',
          label: 'ID'
        }, {
          value: 'tag_name',
          label: '标签'
        }, {
          value: 'lecturer',
          label: '讲师'
        }
        ], // 选项筛选条件
      options: 'title', // 选项选择结果
      keywords: '', // 输入结果查询
      levelData: [],  // 等级筛选条件
      level: '',  // 等级选择结果
      statusData: [
        {
          value: '0',
          label: '草稿'
        },
        {
          value: '1',
          label: '已发布'
        },
        {
          value: '-1',
          label: '已关闭'
        },
      ], // 状态筛选选项
      status: '', // 选项筛选结果
      page: 1,
      pageSize: 20,
      multipleSelection: [],
      //上传图片
      imageUrl: '',
      formLabelAlign: {
        name: '',
        region: '',
        type: ''
      },
      courseInfo: {}, // 课程数据
      current_info: {}, // 当前点击列表数据
    }
  },
  created() {
    this.queryFun()
    this.getCourses()
    this.getDictionary()
  },
  methods: {
    // 获取课程管理
    getCourses() {
      let url = this.$api.Courses + `?options=${this.options}&keywords=${this.keywords}&level=${this.level}&status=${this.status}&page=${this.page}&pageSize=${this.pageSize}`
      this.$http.get(url, true).then(res => {
        if (res.data.success) {
          this.courseInfo = res.data.data
        }
      })
    },
    // 获取等级
    getDictionary() {
      let url= this.$api.Dictionary + '?key=course_difficulty';
      this.$http.get(url, true).then(res =>{
        if(res.data.success) {
          let arr= []
          for (let i in res.data.data){
            arr.push({
              label: res.data.data[i],
              value: i
            })
          }
          this.levelData= arr
        }
      })
    },
    // 查询
    searchSubmit() {
      this.page= 1;
      this.linkFun();
      this.getCourses();
    },
    // 重置
    resetFun() {
      this.options= 'title';
      this.keywords= '';
      this.level= '';
      this.status= ''
      this.searchSubmit()
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    //分页

    handleCurrentChange(val) {
      this.page= val
      this.linkFun()
      this.getCourses()
    },
    //弹窗关闭
    handleClose(done) {
      done();
    },
    //新增标签
    add() {
      this.$router.push('/courses/create')
    },
    //下拉点击
    handleCommand(command) {
      this.current_info= command[1]
      if(command[0] === 'edit') {
        // 编辑
        this.$router.push({path: `/courses/edit/${command[1].uuid}`})
      }else if(command[0] === 'close' || command[0] === 'push'){
        // 关闭 // 发布
        this.$confirm('这是您操作比较重要的数据, 你确定要操作改课程吗？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          let url= this.$api.Courses + '/' + this.current_info.uuid + this.$api.changeStatus;
          this.$http.get(url, true).then(res => {
            if(res.data.success){
              this.$message.success('设置成功')
              this.getCourses()
            }else {
              this.$message.error(res.data.message)
            }
          }).catch(error => {
            this.$message.error(error.response.data.message)
          })
        }).catch(() => {});
      }else if(command[0] === 'del'){
        // 删除
        this.$confirm('这是您操作比较重要的数据, 你确定要删除该课程吗？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          let url= this.$api.Courses + '/' + this.current_info.uuid;
          this.$http.del(url, true).then(res => {
            if(res.data.success){
              this.$message.success('删除成功')
              this.getCourses()
            }else {
              this.$message.error(res.data.message)
            }
          }).catch(error => {
            this.$message.error(error.response.data.message)
          })
        }).catch(() => {});

      }else if([1, 2, 3].indexOf(parseInt(command[0])) != -1){
        this.$confirm(`这是您操作比较重要的数据, 你确定要改变该数据？`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          let url= this.$api.Courses + '/' + this.current_info.uuid + '/' + this.$api.changeContentStatus;
          let data= {
            content_status: command[0]
          }
          this.$http.post(url, data, true).then(res =>{
            if(res.data.success) {
              this.$message.success('设置成功')
              this.getCourses()
            }else {
              this.$message.error(res.data.message)
            }
          }).catch(error => {
            this.$message.error(error.response.data.message)
          })
        }).catch(() => {
        });



      }else if(command[0] === 'view') {
        this.$router.push({path: `/courses/view/${command[1].uuid}`})
      }

    },
    // 设置置顶
    setTop: _.debounce(function (uuid) {
      let url= this.$api.Courses + '/' + uuid + '/' +this.$api.ChangeTop
      this.$http.get(url, true).then(res => {
        if(res.data.success) {
          this.$message.success('设置成功')
          setTimeout(()=>{
            this.getCourses()
          },500)
        }else {
          this.$message.error(res.data.message)
        }
      }).catch(error => {

      })
    },500),
    // 删除课程
    delCourse(uuid) {
      let url= this.$api.Courses + '/'+ uuid;
      this.$http.del(url, true).then(res => {
        if(res.data.success) {
          this.$message.success('删除成功')
        }else {
          this.$message.error(res.data.message)
        }
      }).catch(error => {
        this.$message.error(error.response.data.message)
      })
    },
    // 改变路由参数
    linkFun() {
      this.$router.push({path: '/courses/courses',query: {
          options: this.options,
          keywords: this.keywords,
          level: this.level,
          status: this.status,
          page: this.page,
          pageSize: this.pageSize
        }})
    },
    // 参数赋值
    queryFun() {
      let query= this.$route.query;
      if(query) {
        if(query.options) {
          this.options= query.options;
        }
        if(query.keywords) {
          this.keywords= query.keywords;
        }
        if(query.level) {
          this.level= query.level;
        }
        if(query.status) {
          this.status= query.status;
        }
        if(query.page) {
          this.page= parseInt(query.page);
        }
        if(query.pageSize) {
          this.pageSize= parseInt(query.pageSize);
        }
      }
    }

  },
  components: {
    EmptyPrompt
  }
}
</script>

<style lang="less" scoped>
@import "~assets/less/courses/index.less";
.top-status{
  margin-left: 6px;
  cursor: pointer;
}
.set-line{
  width: 142px;
  height: 1px;
  background: #EBEFF2;
  margin: 10px;
}
</style>
